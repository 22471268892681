import React from 'react';
import Layout from '../layouts/Layout';
import { Helmet } from 'react-helmet';

export const CertDaysTemplate = data => {
  const {
    pageContext: {
      lang
    }
  } = data;


  return (
    <Layout>
      <Helmet>
        <meta name="title" content="Salesforce Certification Days"></meta>
        <meta name="og:title" content="Salesforce Certification Days"></meta>
        <meta name="description" content="Join a free, half-day webinar led by experts designed to help you prepare for your Salesforce Certification." />
        <meta name="og:description" content="Join a free, half-day webinar led by experts designed to help you prepare for your Salesforce Certification." />
        <meta name="keywords" content="Salesforce, Certification Days" />
        <meta property="og:title" content="Salesforce Certification Days" />
        <title>Salesforce Certification Days</title>
      </Helmet>
      <div>
        <tm-page class="theme--salesforce">
          <tm-hero-a background="white" cta-href="#Upcoming_Certification-Days" cta-label="Register for Free" img-align="center" img-src="https://developer.salesforce.com/resources2/cert-days/cert-hero.png" title="Salesforce Certification Days"></tm-hero-a>
          <tm-section-a background="light">
            <tm-feature-grid-a>
              <tm-grid-item description="Ready to grow your resume and highlight your skills? We’re here to help. These sessions are led by expert instructors who share their expertise, tips, and best practices for acing your Salesforce Certification exam." img-src="https://developer.salesforce.com/resources2/cert-days/cert_foliage.png" title="Join a free, half-day webinar to help you prepare for your Salesforce Certification" img-alt=""> </tm-grid-item>
              <tm-grid-item description="There’s no better time to  prove your hands-on experience with Salesforce and get a competitive edge that leads to new opportunities. BONUS: All Certification Day webinar attendees will receive a coupon code upon completion of their session for a $40USD discount off ANY $200 exam!" img-src="https://developer.salesforce.com/resources2/cert-days/cert-feature-1.png" title="Earn a globally-recognized Salesforce Certification" img-alt=""> </tm-grid-item>
            </tm-feature-grid-a>
          </tm-section-a>
          <tm-section-a background="white" description="Select the session below that aligns to the Certification exam you're working towards and the timezone that works best for you." id="Upcoming_Certification-Days" title="Upcoming Certification Days">
            <tm-card-grid-a img-aspect="60%" img-type="full">
              <tm-grid-item cta-href="#administrator" cta-title="View" description="" img-alt="Administrator image" img-src="https://developer.salesforce.com/resources2/cert-days/admin.png" title="Administrator"> </tm-grid-item>
              <tm-grid-item cta-href="#marketing" cta-title="View" description="" img-alt="Marketing image" img-src="https://developer.salesforce.com/resources2/cert-days/mark.png" title="Marketing"> </tm-grid-item>
              <tm-grid-item cta-href="#developer" cta-title="View" description="" img-alt="Developer image" img-src="https://developer.salesforce.com/resources2/cert-days/dev.png" title="Developer"> </tm-grid-item>
              <tm-grid-item cta-href="#consultant" cta-title="View" description="" img-alt="Consultant image" img-src="https://developer.salesforce.com/resources2/cert-days/cons.png" title="Consultant"> </tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>

          {/* Administrator Section */}
          <tm-section-a background="light" id="administrator" title="Preparing for your Administrator Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgkdAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Eastern Standard Time"
                title="May 11, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pglQAAQ"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 24, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pfbsAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time"
                title="June 13, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pfbxAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time (Language: German)"
                title="June 15, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pfc2AAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time (Language: French)"
                title="June 16, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="light" title="Preparing for your Administrator Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}

          {/* Advanced Administrator Section */}
          <tm-section-a background="white" title="Preparing for your Advanced Administrator Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pglGAAQ"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 23, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="white" title="Preparing for your Advanced Administrator Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}

          {/* Marketing Cloud Email Specialist Section */}
          <tm-section-a background="light" id="marketing" title="Preparing for your Marketing Cloud Email Specialist Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgsiAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 10, 2022"
              ></tm-grid-item>
               <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgvwAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time"
                title="June 14, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="light" title="Preparing for your Marketing Cloud Email Specialist Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}

          {/* Platform App Builder Section */}
          <tm-section-a background="white" id="developer" title="Preparing for your Platform App Builder Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgkwAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 19, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pfcCAAQ"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time"
                title="June 17, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="white" title="Preparing for your Platform App Builder Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}

          {/* Developer Section */}
          <tm-section-a background="light" title="Preparing for your Platform Developer Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgl6AAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 20, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pfcHAAQ"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time"
                title="June 20, 2022"
              ></tm-grid-item>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002phkGAAQ"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:30 PM British Summer Time (Language: French)"
                title="June 28, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="light" title="Preparing for your Platform Developer Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}

          {/* Service Cloud Consultant Section */}
          <tm-section-a background="white" id="consultant" title="Preparing for your Service Cloud Consultant Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgjMAAQ"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 9, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="white" title="Preparing for your Service Cloud Consultant Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}

          {/* Sales Cloud Consultant Section */}
          <tm-section-a background="light" title="Preparing for your Sales Cloud Consultant Certification">
            <tm-card-grid-a>
              <tm-grid-item
                cta-href="https://cs.salesforce.com/events/7013y000002pgksAAA"
                cta-target="_blank"
                cta-title="Register"
                description="9:00 AM - 2:00 PM Pacific Standard Time"
                title="May 13, 2022"
              ></tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>
          {/* No Dates */}
          {/* <tm-section-a background="light" title="Preparing for your Sales Cloud Consultant Certification" description="Check back soon as we add more webinars">
          </tm-section-a> */}


          <tm-section-a background="white" description="Can't wait to get started? Here are some great Trailhead Trailmixes and Trails to get you ready:" title="Learning Trailmixes">
            <tm-card-grid-a img-aspect="60%" img-type="left">
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/content/learn/trails/administrator-certification-prep`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Administrator Credential" img-alt="Trailhead Logo"> </tm-grid-item>
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-advanced-administrator-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Advanced Administrator Credential" img-alt="Trailhead Logo"> </tm-grid-item>
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-marketing-cloud-email-specialist-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Marketing Cloud Email Specialist Credential" img-alt="Trailhead Logo"> </tm-grid-item>
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/00550000006yDdKAAU/trailmixes/prepare-for-your-salesforce-platform-app-builder-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Platform App Builder Credential" img-alt="Trailhead Logo"> </tm-grid-item>
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-platform-developer-i-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Platform Developer I Credential" img-alt="Trailhead Logo"> </tm-grid-item>
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-sales-cloud-consultant-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Sales Cloud Consultant Credential" img-alt="Trailhead Logo"> </tm-grid-item>
              <tm-grid-item cta-href={`https://trailhead.salesforce.com/${lang}/users/strailhead/trailmixes/prepare-for-your-salesforce-service-cloud-consultant-credential`} cta-target="_blank" cta-title="Start" img-src="https://developer.salesforce.com/resources2/cert-days/Trailhead-Logo.png" pretitle="Trailmix" title="Prepare for Your Salesforce Service Cloud Consultant Credential" img-alt="Trailhead Logo"> </tm-grid-item>
            </tm-card-grid-a>
          </tm-section-a>

          <tm-end-cap-a background="dark" img-alt="Certified Professional Logo" cta-href="https://trailhead.salesforce.com/credentials/administratoroverview" cta-label="Let's Go!" cta-target="_blank" img-src="https://developer.salesforce.com/resources2/cert-days/certifications.png" subtitle="Looking to learn more about certifications? Checkout our landing pages for more information" title="Learn More about Certifications"> </tm-end-cap-a>

          <tm-footnote tm-footnote_footnote-host="">
            <h3>
              Note
            </h3>

            <p>
              Sessions are not recorded and will not include a practice org, practice test, ebook, or any other additional materials.
            </p>

            <p>
              Sessions and the content presented may not be recorded or used for promotion.
            </p>

            <p>
              <a href="https://salesforce.quip.com/QmWXABObJDDI" target="_blank">Check out our Certification Days FAQ</a>
            </p>
          </tm-footnote>
        </tm-page>
        <th-element-loader element="tm-page" style={{ height: '100vh' }}></th-element-loader>
      </div>
    </Layout>
  );
};

export default CertDaysTemplate;
